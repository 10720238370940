import React, { useState } from 'react';

import { sendEmail } from '../api/mail';

function PopupForm({ isR7, handleClose }) {
  const [formData, setFormData] = useState({
    fullName: '',
    company: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s()-]{7,}$/;

    if (!formData.fullName.trim())
      newErrors.fullName = 'Nombre y Apellido es requerido.';
    if (!formData.company.trim()) newErrors.company = 'Empresa es requerida.';
    if (!formData.email.trim())
      newErrors.email = 'Correo electrónico es requerido.';
    else if (!emailRegex.test(formData.email))
      newErrors.email = 'Correo electrónico no es válido.';
    if (!formData.phone.trim()) newErrors.phone = 'Teléfono es requerido.';
    else if (!phoneRegex.test(formData.phone))
      newErrors.phone = 'Teléfono no es válido.';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log('Form Data:', formData);
      sendEmail({ ...formData, is_r7: isR7 });

      setFormData({
        fullName: '',
        company: '',
        email: '',
        phone: '',
        message: '',
      });

      handleClose(true);
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
      <div className='bg-white rounded-lg p-6 w-[32rem] shadow-lg'>
        <div className='flex justify-end'>
          <button
            className='text-gray-500 hover:text-gray-700'
            onClick={() => handleClose(false)}
          >
            ✕
          </button>
        </div>
        <h2 className='text-center text-xl mb-4'>
          Contáctate con el canal oficial de Motorola Solutions en Chile y obtén
          tu{' '}
          <span className='font-semibold'>
            {isR7 ? 'MOTOTRBO R7' : 'MOTOTRBO R2'}
          </span>
        </h2>
        <form>
          <div className='grid grid-cols-2 gap-4 mb-4'>
            <input
              type='text'
              placeholder='*Nombre y Apellido'
              className='border border-gray-300 rounded p-2 w-full'
              name='fullName'
              value={formData.fullName}
              onChange={handleChange}
            />
            <input
              type='text'
              placeholder='*Empresa'
              className='border border-gray-300 rounded p-2 w-full'
              name='company'
              onChange={handleChange}
              value={formData.company}
            />
          </div>
          <div className='grid grid-cols-2 gap-4 mb-4'>
            <input
              type='email'
              placeholder='*Correo electrónico'
              className='border border-gray-300 rounded p-2 w-full'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type='tel'
              placeholder='*Teléfono'
              className='border border-gray-300 rounded p-2 w-full'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <textarea
            placeholder='Mensaje...'
            value={formData.message}
            className='border border-gray-300 rounded p-2 w-full mb-4'
            name='message'
            onChange={handleChange}
          ></textarea>
          <div className='text-red-500 mb-2'>
            {Object.values(errors).map((error) => (
              <p>{error}</p>
            ))}
          </div>
          <button
            type='submit'
            className='bg-blue-500 text-white font-bold py-2 px-4 rounded w-full hover:bg-blue-600'
            onClick={handleSubmit}
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export default PopupForm;
